import { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import BackgroundImage from './ipadBackground.png';
import LogoDel from './logo.svg';
import Camera from './icon-camera.svg';
import Close from './close.svg';

import pdf1id0 from './PDFs/ID0/EquipmentParts.pdf';
import pdf2id0 from './PDFs/ID0/WorkRequest.pdf';
import pdf3id0 from './PDFs/ID0/FindPartSuppliers.pdf';
import pdf4id0 from './PDFs/ID0/KimrayTechnicalSheet.pdf';

import pdf1id2 from './PDFs/ID2/ImageAnalysis.pdf';
import pdf2id2 from './PDFs/ID2/Contractors.pdf';
import pdf3id2 from './PDFs/ID2/LocationMap.pdf';
import pdf4id2 from './PDFs/ID2/WorkOrder.pdf';

import pdf1id3 from './PDFs/ID3/PartSuppliers.pdf';
import pdf2id3 from './PDFs/ID3/PurchaseOrder.pdf';
import pdf3id3 from './PDFs/ID3/PumpSheet.pdf';
import pdf4id3 from './PDFs/ID3/Email.pdf';

function App() {

  const [search, setSearch] = useState('');
  const [wordSearched, setWordSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  const upload = useRef(undefined);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (search !== '') {
        setWordSearch(search)
        setLoading(true);
        setSearched(true);
      }
    }
  }

  const onChangeImage = (event) => {
    setLoading(true);
    setSearched(true);
    setSearch(event.target.files[0].name);
    setWordSearch(event.target.files[0].name);
  }

  const clearSearch = () => {
    setSearched(false);
    setSearch('');
    setWordSearch('');
  }

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 2302)
    }
  }, [loading]);

  const regex = new RegExp('(.*)\.jpg|JPG');

  return (
    <>
      <GlobalStyle />
      <Container img={BackgroundImage}>
        <Header>
          <Logo src={LogoDel} />
          <RefreshButton onClick={() => window.location.reload()}>
            Restart
          </RefreshButton>
        </Header>
        <BodyContent>
          <Title>AI-Powered Contextual Insights</Title>
          <DivInput>
            <StyledInput
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              value={search}
              searched={searched}
            />
            <ButtonUpload onClick={() => upload.current.click()}><img src={Camera} alt="button insert file" /></ButtonUpload>
            {search !== '' &&
              <ButtonClose onClick={() => clearSearch()}><img src={Close} alt="button clear search" /></ButtonClose>
            }
            <InputFile ref={upload} type="file" accept="image/JPG" accept="image/jpg" onChange={onChangeImage} />
          </DivInput>
          {searched &&
            <BoxResponse searched={searched} loading={loading.toString()}>
              {loading &&
                <Spinner>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </Spinner>
              }
              {!loading &&
                <>
                  {regex.test(wordSearched) &&
                    <>
                      {wordSearched === 'TreeBranch.JPG' &&
                        <ContainerOptions>
                          <Option href={pdf1id2} target='_blank'>
                            View Image Analysis
                          </Option>
                          <Option href={pdf2id2} target='_blank'>
                            Find Contractors
                          </Option>
                          <Option href={pdf3id2} target='_blank'>
                            View Location Map
                          </Option>
                          <Option href={pdf4id2} target='_blank'>
                            Create Work Order
                          </Option>
                        </ContainerOptions>
                      }
                      {wordSearched === 'PumpJack.JPG' &&
                        <ContainerOptions>
                          <Option href={pdf1id3} target='_blank'>
                            Find Part Suppliers
                          </Option>
                          <Option href={pdf2id3} target='_blank'>
                            Create Purchase Order
                          </Option>
                          <Option href={pdf3id3} target='_blank'>
                            View Technical Sheet
                          </Option>
                          <Option href={pdf4id3} target='_blank'>
                            Create Email Request
                          </Option>
                        </ContainerOptions>
                      }
                      {((wordSearched !== 'TreeBranch.JPG') && (wordSearched !== 'PumpJack.JPG')) &&
                        <ContainerOptions>
                          <Option href={pdf1id0} target='_blank'>
                            View Equipment Parts
                          </Option>
                          <Option href={pdf2id0} target='_blank'>
                            Create Work Request
                          </Option>
                          <Option href={pdf3id0} target='_blank'>
                            Find Part Suppliers
                          </Option>
                          <Option href={pdf4id0} target='_blank'>
                            View Technical Sheet
                          </Option>
                        </ContainerOptions>
                      }
                    </>
                  }
                  {!regex.test(wordSearched) &&
                    <>
                      {(wordSearched.toLowerCase() === 'burns ranch') &&
                        <ContainerOptions>
                          <Option href={pdf1id3} target='_blank'>
                            Find Part Suppliers
                          </Option>
                          <Option href={pdf2id3} target='_blank'>
                            Create Purchase Order
                          </Option>
                          <Option href={pdf3id3} target='_blank'>
                            View Technical Sheet
                          </Option>
                          <Option href={pdf4id3} target='_blank'>
                            Create Email Request
                          </Option>
                        </ContainerOptions>
                      }
                      {(wordSearched.toLowerCase() !== 'burns ranch') &&
                        <ContainerOptions>
                          No Site Location Found...
                        </ContainerOptions>
                      }
                    </>
                  }
                </>
              }
            </BoxResponse>
          }
        </BodyContent>
      </Container>
    </>
  );
}

export default App;


const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'OpenSansBold'
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
const Container = styled.div`
  margin: 0;
  padding: 0;
  background: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: inline-flex;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
  box-sizing:border-box;
  @media screen and (max-width: 600px){
    padding: 40px 20px;
  }
`;
const Logo = styled.img`
  object-fit: contain;
  @media screen and (max-width: 600px){
    max-width: 205px;
  }
`;
const RefreshButton = styled.div`
  width: 80px;
  height: 40px;
  display: flex;
  font-family: Helvetica;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  box-sizing:border-box;
  :hover{
    cursor: pointer;
    background-color: green;
  }
`;
const BodyContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Helvetica;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
`;
const Title = styled.h2`
  color: white;
  font-weight: 500;
`;
const DivInput = styled.div`
  margin-top: 24px;
  position: relative;
  width: 100%;
  max-width: 640px;
  height: 40px;
`;
const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 24px;
  outline: none;
  border: none;
  box-shadow: inset 0 0.0625em 0.125em rgb(0 0 0 / 65%);
  padding: 0 52px;
  display: flex;
  align-items: center;
  ${({ searched }) => searched && css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;
const InputFile = styled.input`
  display:none;
`;
const ButtonUpload = styled.button`
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  left: 8px;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    cursor: pointer;
  }
`;
const ButtonClose = styled.button`
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  right: 8px;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    cursor: pointer;
  }
`;
const BoxResponse = styled.div`
  display: flex;
  flex-flow: column;
  background: #fff;
  border-top: 1px solid #D9D9D9;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 24px 16px;
  width: 100%;
  max-width: 640px;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
  ${({ searched }) => searched && css`
    min-height: 265px;
    height: 100%;
    opacity: 1;
  `}
  ${({ loading }) => loading === 'true' && css`
    align-items: center;
    justify-content: center;
  `}
`;
const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #26890D;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #26890D transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`
const ContainerOptions = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;
const Option = styled.a`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  margin-bottom: 8px;
  color: #0076A8;
  text-decoration: none;
  :last-child{
    margin-bottom:0;
  }
  :hover{
    cursor: pointer;
  }
`;
